import React, { useEffect, useState } from 'react';
import img1 from '../assets/img/bg/bg.jpg'
import TeaProduct from './TeaProduct'
import icon1 from '../assets/icons/fast-delivery.png'
import icon2 from '../assets/icons/secureM.png'
import icon3 from '../assets/icons/badge.png'
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//import { addToCartAction } from '../redux/actions/cartActions';
//import { cartreducer } from '../redux/reducers';

import AgroProducts from './AgroProducts';
import ProductList from './ProductList';

import ProductService from '../services/ProductService';
import CartService from '../services/CartService';

function ProductInfo() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    //const dispatch = useDispatch();
    //const cartData = useSelector((state) => state.cartReducer.cartData);

    const { id } = useParams();
    //console.log(id);
    const products = useSelector((state) => state.cartreducer.products);
    const [product, setProduct] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [prices, setPrices] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0); // Initialize with the first button as active
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [productImgArr, setProductImgArr] = useState([]);
    

    const isLoggedIn = localStorage.getItem('isLoggedIn');

    const getData = useSelector((state) => state.cartreducer.products);

    // const handleQuantityChange = (increment) => {
    //     setQuantity((prevQuantity) => Math.max(prevQuantity + increment, 1));
    // };

    const handleQuantityChange = (increment) => {
        const newQuantity = Math.max(selectedQuantity + increment, 1);
        setSelectedQuantity(newQuantity);
    };

    //setData(getData);


    /* const compare = () =>{
        let comparedata = getData.filter((e)=>{
            return e.id == id
        });
        setData(comparedata);
    }

    useEffect(()=>{
        compare()
    },[id]) 
 */

    useEffect(() => {

        const productService = new ProductService();
        // Use the fetchProductDetails function to get the product details based on the id
        productService.fetchProductDetails(id)
            .then((productDetails) => {
                // console.log(productDetails, "productDetails");
                setProduct(productDetails);
                // Set the default selected unit and price based on the first unit if prices are available
                if (productDetails && productDetails.length > 0 && productDetails[0].prices) {
                    const initialUnitName = productDetails[0].prices[0].unit_name;
                    setSelectedUnit(initialUnitName); // Select the first unit by name as default
                    setPrices(productDetails[0].prices);
                    // Set the default selected price based on the first price for the selected unit
                    const initialPrice = productDetails[0].prices.find(price => price.unit_name === initialUnitName);
                    setSelectedPrice(initialPrice);
                }
                setProduct(productDetails);
            })
            .catch((error) => {
                console.error('Error fetching product details:', error);
            });
    }, [id]);
    
    // Don't take off this code though it is not used anywhere--> its a workaround
    useEffect(()=>{
        setProductImgArr(product?.[0]?.images);
    }, [product])
   
    
    const handleUnitClick = (index, unitName, price) => {
        setActiveButtonIndex(index);
        setSelectedUnit(unitName);
        setSelectedPrice(price);
        // You can also update the quantity here if needed.
        // Reset the selected quantity when changing the unit
        setSelectedQuantity(1);
    };


    const handleAddToCart = async () => {
        try {

            const cartService = new CartService();
            const user_id = localStorage.getItem('userid');
            const session_key = localStorage.getItem('session_key');
            const product_id = product[0].id;
            const in_stockstatus = product[0].in_stock;
            const product_price_id = selectedPrice ? selectedPrice.product_price_id : 0;
            const price_qty = selectedPrice ? selectedPrice.qty : 0;
            const price_unit_name = selectedPrice ? selectedPrice.unit_name : 0;
            const qty = selectedQuantity;
            const per_mrp_price = selectedPrice ? selectedPrice.mrp : 0;
            const total_mrp_price = selectedPrice ? qty * per_mrp_price : 0;
            const per_discount = selectedPrice ? selectedPrice.discount : 0;
            const total_discount = selectedPrice ? qty * per_discount : 0;
            const final_per_price = selectedPrice ? selectedPrice.final_price : 0;
            const final_total_price = selectedPrice ? qty * final_per_price : 0;
          
          const productData = {
            user_id: user_id,
            sessionkey: session_key,
            product_id: product_id,
            qty: qty,
            per_mrp_price: per_mrp_price,
            total_mrp_price: total_mrp_price,
            per_discount: per_discount,
            total_discount: total_discount,
            final_per_price: final_per_price,
            final_total_price: final_total_price,
            product_price_id: product_price_id,
            price_qty: price_qty,
            price_unit_name: price_unit_name
          };

          if(in_stockstatus==='INSTOCK'){
            const response = await cartService.addtocart(productData);
        
            // Assuming your API returns a success flag or some response indicating successful cart addition
            if (response.status==true) {
                alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                //window.location.reload();
                navigate('/cart/');
                // Dispatch an action to add the product to the cart
                //dispatch(addToCartAction(productData)); 

            } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
            }
          }else{
            alert('Product is out of stock');
          }
        } catch (error) {
          console.error('Error adding product to cart:', error);
        }
    };

    const handleAddToCartBulk = async () => {
        
        const product_id = product[0].id;
        const in_stockstatus = product[0].in_stock;
        if(in_stockstatus==='INSTOCK'){
            //window.location.href = './bulk-order/'+product_id;
            navigate('/bulk-order/'+product_id);

        }else{
            alert('Product is out of stock');
        }            
    };


    if (!product) {
        // If the product is not found, you can show a loading indicator or an error message.
        return <div>Loading...</div>;
    }

    const bulkorder = {
        color: "#fff",
        background: "#0f5132",
        padding: "10px 20px"
    }

    const handleAddToCartLogin = () => {
        //alert('Add To Cart clicked (Login)');
        const product_id = product[0].id;
        navigate('/login/product/'+product_id);
    };
    
    const handlePlaceBulkOrderLogin = () => {
        //alert('Place Bulk Order clicked (Login)');
        navigate('/login/bulkorder');
    };

    return (
        <>
            <section className='products_info pt-4'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 d-flex justify-content-center flex-colunm">
                            {product[0] && product[0].images ? (
                                <OwlCarousel
                                    items={1}
                                    className="owl-theme"
                                    nav={false}
                                    dots={true}
                                    autoplay={true}
                                    margin={8}
                                >
                                    {/* {console.log(productImgArr,"image")} */}
                                    {product[0].images.map((image, imageindex) => (
                                        <div key={imageindex} className='item'>
                                            <img src={image} alt={`product ${imageindex}`} className='img-fluid' />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            ) : (
                                <p>No images available.</p>
                            )}
                        </div>
                        <div className="col-lg-7">
                            <div className='mt-2 mt-lg-2'>
                                <h5 className='product_title'>{product[0].product_name}</h5>
                                {/* <p className='in_stock'>{product[0].in_stock}</p> */}
                                <p className='in_stock' style={{ backgroundColor: product[0].in_stock === 'OUTOFSTOCK' ? '#FBAB7E' : '' }}>
                                    {product[0].in_stock}
                                </p>
                                <h5>MRP : ₹ {selectedPrice ? selectedPrice.mrp : ''} 
                                {/* <span className='discount'>Discount : ₹ {selectedPrice ? selectedPrice.discount : ''}</span> */}&nbsp;
                                {selectedPrice && selectedPrice.discount !== '0.00' && (
                                <span className='discount'>Discount : ₹ {selectedPrice.discount}</span>
                                )}
                                </h5>
                                <p className='text_taxes'>Inclusive all taxes</p>
                                <div>
                                    <h5 className='fs-4'>Final price : ₹ {selectedPrice ? selectedPrice.final_price : ''}</h5>
                                </div>
                                <div className='ml_button mt-4'>
                                    <div className='mb-3 d-flex' style={{minWidth:'100%', width:'max-content'}}>
                                        {prices.map((price, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={` me-2 nav-link ${(index === activeButtonIndex) ? 'btn btn-outline-success active text-white' : 'btn btn-outline-secondary'}`}
                                                style={{ padding: '10px 8px',width:'80px' }}
                                                onClick={() => handleUnitClick(index, price.unit_name, price)}
                                            >
                                                {price.qty} {price.unit_name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                
                                {isLoggedIn ? (
                                    <div className='d-lg-flex d-block mt-4' style={{gap:'5px'}}>
                                        <div className="btn-group" role="group" aria-label="Basic outlined example">
                                            <button type="button" className="btn btn-outline-success" onClick={() => handleQuantityChange(-1)}>-</button>
                                            <button type="button" className="btn btn-outline-success">{selectedQuantity}</button>
                                            <button type="button" className="btn btn-outline-success" onClick={() => handleQuantityChange(1)}>+</button>
                                        </div>
                                        <div className='mt-2 mt-lg-0'>
                                            <button type="button" className="btn btn-success" style={{width:'100%'}} onClick={handleAddToCart}>Add To Cart</button>
                                        </div>
                                        <div className='mt-2 mt-lg-0'>
                                            <button type="button" className="btn btn-success" style={{width:'100%'}} onClick={handleAddToCartBulk}>Place Bulk Order</button>
                                            {/* <NavLink to={`/bulk-order/${product[0].id}`} style={bulkorder}>
                                                Place Bulk Order
                                            </NavLink> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className='d-lg-flex d-block mt-4' style={{gap:'5px'}}>
                                            
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" className="btn btn-outline-success" onClick={handleAddToCartLogin}>-</button>
                                                <button type="button" className="btn btn-outline-success">1</button>
                                                <button type="button" className="btn btn-outline-success" onClick={handleAddToCartLogin}>+</button>
                                            </div>
                                            <div className='mt-2 mt-lg-0'>
                                                <button type="button" className="btn btn-success" style={{width:'100%'}} onClick={handleAddToCartLogin}>Add To Cart</button>
                                            </div>
                                            
                                            <div className='mt-2 mt-lg-0'>
                                                <button type="button" className="btn btn-success" style={{width:'100%'}} onClick={handlePlaceBulkOrderLogin}>Place Bulk Order</button>
                                            </div>
                                        </div>
                                        {/* <NavLink to="/bulk-order" style={bulkorder}>Place Bulk Order</NavLink> */}
                                    </div>
                                )}
                                
                                    <ul className='d-flex justify-content-center justify-content-lg-start ps-0 mt-4'>
                                        <li className='me-4'>
                                            <img src={icon1} alt="icons1" width='50' className='d-block m-auto' />
                                            <p className='text-center' style={{fontSize:'13px'}}><strong>Fast Delivery</strong></p>
                                        </li>
                                        <li className='me-4'>
                                            <img src={icon2} alt="icons2" width='50' className='d-block m-auto' />
                                            <p className='text-center' style={{fontSize:'13px'}}><strong>Secure Payment</strong></p>
                                        </li>
                                        <li className='me-3'>
                                            <img src={icon3} alt="icons3" width='50' className='d-block m-auto' />
                                            <strong><p className='text-center' style={{fontSize:'13px'}}>Top Brand</p></strong>
                                        </li>
                                    </ul>
                                
                            </div>
                        </div>
                        <div className='pt-3'>
                            <h5 className='fs-4 ps-2' style={{borderLeft:'4px solid #009a00'}}>About the Product</h5>
                            <div className='mt-2'>
                                <h6 className='fs-6'>Category : {product[0].category}</h6>
                                <h6 className='fs-6'>SKU : {product[0].sku_number}</h6>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: product[0].product_description }} />
                        </div>
                    </div>      
                    <hr/>
                    <div>
                        <h4 className='text-center'>Product Related to this item</h4>
                        <ProductList category={product[0].categorykey} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductInfo