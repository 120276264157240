import React, { useState, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoCalendarSharp } from 'react-icons/io5';
import BlogSideInfo from './BlogSideInfo';
import { useParams } from 'react-router-dom';
import BlogService from '../services/BlogService';

function BlogDetails() {
    const [blogPost, setBlogPost] = useState({});
    const { title } = useParams();

    useEffect(() => {
        // Fetch data when the component mounts
        const service = new BlogService();
        service.fetchBlodDetails(title)
            .then((data) => setBlogPost(data))
            .catch((error) => console.error('Error fetching blog post details:', error));
    }, [title, setBlogPost]);

    const formatDate = (dateString) => {
        // Implement date formatting logic as needed
        return dateString; // Change this to format the date
    };

    return (
        <>
            <section>
                <div className='pb-3 pt-3'>
                    <div className="container">
                        <div className='row gy-3'>
                            <div className="col-lg-8">
                                {blogPost && blogPost.length > 0 && (
                                    <div>
                                        <div className='pb-3'>
                                            <img src={blogPost[0].blog_logo} alt={blogPost[0].blog_title_display} width='100%' height='100%' />
                                        </div>
                                        <div className='d-flex gap-3 pb-3'>
                                            <p className='mb-0'><FaUser /> {blogPost[0].postedby_name}</p>
                                            <p className='mb-0'><IoCalendarSharp /> {formatDate(blogPost[0].posted_on)}</p>
                                        </div>
                                        <div>
                                            <h1 className='fs-3'>{blogPost[0].blog_title_display}</h1>
                                            {/* <p>{blogPost[0].blog_description}</p> */}
                                            <div dangerouslySetInnerHTML={{ __html: blogPost[0].blog_description }} />
                                            {/* Render other dynamic content as needed */}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-4">
                                <BlogSideInfo />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogDetails;
