import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Aboutus from './components/Aboutus';
import Footer from './components/Footer';
import ProductDetailsPopup from './components/ProductDetailsPopup';
import store from './store';
import { Provider } from 'react-redux';
import Products from './components/Products';
import PlantsPage from './components/PlantsPage';
import AgroProductPage from './components/AgroProductPage';
import VegetableSeedProduct from './components/VegetableSeedProduct';
import TeaProductPage from './components/TeaProductPage';
import contactus from './components/contactus';
import Error from './components/Error';
import ProductInfo from './components/ProductInfo';
import ScrollToTop from './components/ScrollToTop';
import AddToCart from './components/AddToCart';
import Checkout from './components/Checkout';
//import Dashboard from './components/Dashboard';
import MyOrder from './components/MyOrder';
import MyAccount from './components/MyAccount';
import Login from './components/Login';
import Signup from './components/Signup';
import PaymentStatus from './components/PaymentStatus';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentError from './components/PaymentError';
import ForgotPassword from './components/ForgotPassword';
import OrderTrack from './components/OrderTrack';
import Bulkorder from './components/Bulkorder';
import PrivacyPolicy from './components/PrivacyPolicy';

import Blog from './components/Blog';
import BlogDetails from './components/BlogDetails';
import SearchItem from './components/SearchItem';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <BrowserRouter>
    <React.StrictMode>
      <Header/>
      <ScrollToTop/>
      <ProductDetailsPopup/>
      <Routes>
        <Route exact path='/' Component={App}/>
        <Route exact path='/products/:category' Component={Products}/>
        <Route exact path='/plants' Component={PlantsPage}/>
        <Route exact path='/agro' Component={AgroProductPage}/>
        <Route exact path='/vegetable-seeds' Component={VegetableSeedProduct}/>
        <Route exact path='/tea-product' Component={TeaProductPage}/>
        <Route path='/about-us' Component={Aboutus}/>
        <Route path='/contact-us' Component={contactus}/>
        <Route path='/cart' Component={AddToCart}/>
        <Route path='/checkout' Component={Checkout}/>
        <Route path='/profile' Component={MyAccount}/>
        {/* <Route path='/dashboard' Component={Dashboard}/> */}
        <Route path='/order' Component={MyOrder}/>
        <Route path='/product-info/:id' Component={ProductInfo}/>
        <Route path='/track-order' Component={OrderTrack}/>
        <Route path='/bulk-order/:id' Component={Bulkorder}/>
        <Route path='/login/:param?/:param2?' Component={Login}/>
        <Route path='/signup/:param?/:param2?' Component={Signup}/>
        <Route path='/payment-status' Component={PaymentStatus}/>
        <Route path='/payment-success' Component={PaymentSuccess}/>
        <Route path='/payment-error' Component={PaymentError}/>
        <Route path='/forgot-password/:param?/:param2?' Component={ForgotPassword}/>
        <Route path='/privacy-policy' Component={PrivacyPolicy}/>
        <Route path='*' Component={Error} />

        <Route path='/blog' Component={Blog}/>
        <Route path='/blog-details/:title' Component={BlogDetails}/>

        <Route path='/search-item' Component={SearchItem}/>

      </Routes>
      <Footer/>
    </React.StrictMode>
  </BrowserRouter>
  </Provider>
);

reportWebVitals();
