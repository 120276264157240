import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Plants from '../components/Plants';
import Pagination from '@mui/material/Pagination';
//import Stack from '@mui/material/Stack';

import { useDispatch } from 'react-redux';
import {ADD, ADD1} from '../redux/actions/action';
import { NavLink } from 'react-router-dom';
import { RiShoppingCartFill } from 'react-icons/ri'
import { Rating, Stack } from '@mui/material'

/* import img1 from '../assets/img/product/cta-bg-1.webp';
import img2 from '../assets/img/product/cta-bg-2.webp';
import img3 from '../assets/img/product/cta-bg-3.webp'; */

// Import the productService
import ProductService from '../services/ProductService';
import CategoryService from '../services/CategoryService';
import CartService from '../services/CartService';

function Products() {

    const dispatch = useDispatch();

    const send = (e) =>{
        dispatch(ADD(e));
    }
    const send1 = (e) =>{
        dispatch(ADD1(e));
    }

    // State to store the products
    const [products, setProducts] = useState([]);
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [productsPerPage] = useState(8); // Number of products to display per page

    const [selectedProductPrice, setSelectedProductPrice] = useState(null); // State to store selected product price
    const [selectedPrices, setSelectedPrices] = useState({}); // State to store selected prices for each product

    const isLoggedIn = localStorage.getItem('isLoggedIn');
    
    // Extract the category value from the URL using useParams()
    const { category } = useParams();
    //console.log(category);
    // Fetch products based on the category when the component mounts or when the category changes
    useEffect(() => {
        const productService = new ProductService();
        productService.fetchProductsByCategory(category)
        .then((data) => {
            setProducts(data);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [category]);

    useEffect(() => {
        const categoryService = new CategoryService();
        categoryService
          .fetchCategoryDetails(category)
          .then((data) => {
            setCategoryDetails(data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, [category]);
      /*console.log('categoryDetails', categoryDetails?.[0]?.category);*/

      const addToCart = async (product,selectedPrice) => {
        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.id;
            var in_stockstatus = product.in_stock;
            // var product_price_id = product.product_price_id;
            // var price_qty = product.price_qty;
            // var price_unit_name = product.price_unit_name;
            // var qty = 1;
            // var per_mrp_price = product.price_mrp;
            // var total_mrp_price = (qty * product.price_mrp);
            // var per_discount = product.price_discount;
            // var total_discount = (qty * product.price_discount);
            // var final_per_price = product.price_final_price;
            // var final_total_price = (qty * product.price_final_price);
            var product_price_id = selectedPrice ? selectedPrice.product_price_id : product.product_price_id;
            var price_qty = selectedPrice ? selectedPrice.price_qty : product.price_qty;
            var price_unit_name = selectedPrice ? selectedPrice.price_unit_name : product.price_unit_name;
            var qty = 1;
            var per_mrp_price = selectedPrice ? selectedPrice.price_mrp : product.price_mrp;
            var total_mrp_price = (qty * per_mrp_price);
            var per_discount = selectedPrice ? selectedPrice.price_discount : product.price_discount;
            var total_discount = (qty * per_discount);
            var final_per_price = selectedPrice ? selectedPrice.price_final_price : product.price_final_price;
            var final_total_price = (qty * final_per_price);

            const productData = {
                user_id: user_id,
                sessionkey: session_key,
                product_id: product_id,
                product_price_id: product_price_id,
                price_qty: price_qty,
                price_unit_name: price_unit_name,
                qty: qty,
                per_mrp_price: per_mrp_price,
                total_mrp_price: total_mrp_price,
                per_discount: per_discount,
                total_discount: total_discount,
                final_per_price: final_per_price,
                final_total_price: final_total_price
            };
            //console.log('adddhi',productData);

            if(in_stockstatus==='INSTOCK'){
                const response = await cartService.addtocart(productData);
        
                // Assuming your API returns a success flag or some response indicating successful cart addition
                if (response.status==true) {
                alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                window.location.reload();
                } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
                }
            }else{
                alert('Product is out of stock');
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
      };
    

    const handlePriceChange = (event, product) => {
        const selectedPriceId = event.target.value;
        //alert(selectedPriceId);
        const selectedPrice = product.pricesArr.find(price => price.product_price_id === selectedPriceId);
        setSelectedProductPrice(selectedPrice);
        setSelectedPrices(prevState => ({
            ...prevState,
            [product.id]: selectedPrice
        }));
    };
    // Calculate the index of the last product on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    // Calculate the index of the first product on the current page
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    // Get the current products to display based on the current page
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change the current page
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <section className='product_page'>
                <div className='plant_banner'>
                {categoryDetails ? ( // Add this condition to check if categoryDetails is available
                    <>
                        <h1 className='text-center'>{categoryDetails[0].category}</h1>
                        <nav className="breadcrumb d-flex justify-content-center">
                        <NavLink className="breadcrumb-item" to="/">Home</NavLink>
                        <span className="breadcrumb-item active" aria-current="page">{categoryDetails[0].category}</span>
                        </nav>
                    </>
                    ) : (
                    <h1 className='text-center'>Loading...</h1> // Show a loading message while categoryDetails is null
                    )}
                </div>

                <div className="container">
                <section className='Plant_items mt-3'>
                    <div className='container-lg'>
                        {currentProducts.length ? (
                        <>
                        <div className='row gx-1 gy-3'>
                            {
                                currentProducts.map((element,i) => {
                                    return (
                                        <>
                                            <div className='col-lg-3 col-md-3 cds'>
                                                <div className="card" style={{ width: '95%', margin: 'auto' }}>
                                                    <NavLink to={`/product-info/${element.id}`}>
                                                        <img key={i} src={element.image} className="card-img-top" alt={element.alt} onClick={()=> send1(element)} />
                                                    </NavLink>
                                                    <div className="card-body">
                                                        <p className="card-title"><NavLink to={`/product-info/${element.id}`}>{element.product_name} - {element.products_units_all}</NavLink></p>
                                                        
                                                        <Stack spacing={1}>
                                                            <Rating name='half-rating' defaultValue={4.5} precision={0.5} size='small'/>
                                                        </Stack>
                                                        <Stack spacing={1}>
                                                            {/* {element.price_qty} {element.price_unit_name} */}
                                                            <div className='pb-2'>
                                                                <select class="form-select" aria-label="Default select example" onChange={(event) => handlePriceChange(event, element)}>
                                                                    {/* <option value={`${element.price_qty} ${element.price_unit_name}`}>{element.price_qty} {element.price_unit_name}</option> */}
                                                                    {element.pricesArr.map(price => (
                                                                        <option key={price.product_price_id} value={`${price.product_price_id}`}>
                                                                        {`${price.price_qty} ${price.price_unit_name}`}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                        </Stack>
                                                        <div className='card-bottom'>
                                                            {/* <p><b>&#8377; {element.price_final_price}</b></p> */}
                                                            <p><b>&#8377; {selectedPrices[element.id]?.price_final_price || element.price_final_price}</b></p>
                                                            {isLoggedIn ? (
                                                                <RiShoppingCartFill className='cart_icon bg-success text-light p-1 rounded' title='Add To Cart' onClick={()=> addToCart(element,selectedPrices[element.id])} />
                                                            ) : (
                                                                <NavLink to={`/login/product/${element.id}`}>
                                                                    <RiShoppingCartFill
                                                                    className='cart_icon bg-success text-light p-1 rounded'
                                                                    title='Add To Cart'
                                                                    />
                                                                </NavLink>
                                                            )}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        </>
                        ) : (
                        <div className='row gx-1 gy-3'>
                            <h1 className='text-center'>No Products Found!</h1>
                        </div>
                        )}
                    </div>
                </section>
                    {products.length > 0 ? ( // Add this condition to check if categoryDetails is available
                    <>
                        <Stack spacing={2}  className='mt-4 mb-4'>
                            {/* <Pagination count={10} size="small" shape="rounded" /> */}
                            <Pagination
                                count={Math.ceil(products.length / productsPerPage)} // Calculate the total number of pages
                                page={currentPage} // Set the current page
                                onChange={handlePageChange} // Handle page changes
                                size="small"
                                shape="rounded"
                                />
                        </Stack>
                    </>
                    ) : (
                        <Stack spacing={2}  className='mt-4 mb-4'></Stack>
                    )}
                    
                    {/* <div className='pb-3'>
                        <h1 className='pt-3 pb-3'>Related Product</h1>
                        <div className="row gy-3">
                            <div className="col-lg-4">
                                <div>
                                    <img src={img1} alt="img1" className='img-fluid rounded'/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div>
                                    <img src={img2} alt="img2" className='img-fluid rounded'/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div>
                                    <img src={img3} alt="img3" className='img-fluid rounded' />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Products