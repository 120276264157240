import React, { useState, useEffect } from 'react';
import { IoCalendarOutline } from "react-icons/io5";
import { GiCoffeeCup, GiFertilizerBag, GiPlantSeed } from 'react-icons/gi';
import { RiPlantFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';


import BlogService from '../services/BlogService';
import ProductService from '../services/ProductService';

function BlogSideInfo() {

    const [blogPosts, setBlogPosts] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch data when the component mounts
        const service = new BlogService();
        service.fetchBlogsByLimit(4)
            .then((data) => setBlogPosts(data))
            .catch((error) => console.error('Error fetching blog posts:', error));
    }, []);

    // Fetch product categories on component mount
    useEffect(() => {
        const productService = new ProductService();

        productService
        .fetchProductCategories()
        .then((data) => {
            setCategories(data.data); // Assuming the API response has a "data" property
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const title32 = {
        display:'-webkit-box',
        WebkitLineClamp : '1',
        WebkitBoxOrient : 'vertical',
        overflow : 'hidden'
    }
    const desc42 = {
        display:'-webkit-box',
        WebkitLineClamp : '1',
        WebkitBoxOrient : 'vertical',
        overflow : 'hidden'
    }
  return (
    <>
        <div className='mb-3'>
            <div>
                <p className='fw-bold fs-4'>More Posts</p>
            </div>
            <div className='p-3 shadow rounded border'>
                {blogPosts.length === 0 ? (
                    <p>No blog posts available.</p>
                ) : (
                    <>
                        {blogPosts.map((post) => (
                            <div className='d-flex gap-2 mb-3' key={post.id}>
                                <div>
                                    <NavLink to={`/blog-details/${post.blog_url_title}`}>
                                        <img width='150' height='auto' src={post.blog_logo} alt={post.blog_title_display} />
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink to={`/blog-details/${post.blog_url_title}`} className='fw-bold fs-6' style={title32}>{post.blog_title_display}</NavLink>

                                    <p className='mb-0' style={desc42}>{post.short_description_for_list}</p>
                                    <i className='mb-0'><IoCalendarOutline /> {post.posted_on}</i>
                                </div>
                            </div>
                        ))}
                    </>    
                )}
                
            </div>
        </div>

        <div>
            <div>
                <p className='fw-bold fs-4'>Shop Now</p>
            </div>
            <div className='pt-1 pb-1 shadow rounded border'>
                {/* <ul style={{lineHeight:'2', padding:'0',margin:'0'}}>
                    <li className='pt-2 pb-2 ps-4 border-bottom'>
                        <NavLink className='fw-bold' to="/plants"><RiPlantFill /> Plants</NavLink>
                    </li>
                    <li className='pt-2 pb-2 ps-4 border-bottom'>
                        <NavLink className='fw-bold' to="/agro"><GiPlantSeed /> Vegetable Seeds</NavLink>
                    </li>
                    <li className='pt-2 pb-2 ps-4 border-bottom'>
                        <NavLink className='fw-bold' to="/vegetable-seeds"><GiFertilizerBag /> Agro Products</NavLink>
                    </li>
                    <li className='pt-2 pb-2 ps-4'>
                        <NavLink className='fw-bold' to="/tea-product"><GiCoffeeCup /> Tea Products</NavLink>
                    </li>
                </ul> */}
                <ul style={{ lineHeight: '2', padding: '0', margin: '0' }}>
                    {categories.map((category) => (
                        <li key={category.id} className='pt-2 pb-2 ps-4 border-bottom'>
                            <NavLink className='fw-bold' to={`/products/${category.categorykey}`}>
                                {category.category}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
  )
}

export default BlogSideInfo