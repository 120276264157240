import React, { useEffect, useState } from 'react';
import ProductService from '../services/ProductService';
import Pagination from '@mui/material/Pagination';
import { RiShoppingCartFill } from 'react-icons/ri'
import { Rating, Stack } from '@mui/material'

import { useDispatch } from 'react-redux';
import {ADD, ADD1} from '../redux/actions/action';
import { NavLink } from 'react-router-dom';


import CartService from '../services/CartService';

function ProductList({ category, updateCart }) {

    const dispatch = useDispatch();

    const send = (e) =>{
        dispatch(ADD(e));
    }
    const send1 = (e) =>{
        dispatch(ADD1(e));
    }

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [productsPerPage] = useState(8); // Number of products to display per page
  
  const [selectedProductPrice, setSelectedProductPrice] = useState(null); // State to store selected product price
  const [selectedPrices, setSelectedPrices] = useState({}); // State to store selected prices for each product


  const isLoggedIn = localStorage.getItem('isLoggedIn');

  useEffect(() => {
    const productService = new ProductService();

    productService
      .fetchProductsByCategory(category)
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [category]);

  const addToCart = async (product,selectedPrice) => {
    try {
        const cartService = new CartService();
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
        var product_id = product.id;
        var in_stockstatus = product.in_stock;
        // var product_price_id = product.product_price_id;
        // var price_qty = product.price_qty;
        // var price_unit_name = product.price_unit_name;
        // var qty = 1;
        // var per_mrp_price = product.price_mrp;
        // var total_mrp_price = (qty * product.price_mrp);
        // var per_discount = product.price_discount;
        // var total_discount = (qty * product.price_discount);
        // var final_per_price = product.price_final_price;
        // var final_total_price = (qty * product.price_final_price);
        var product_price_id = selectedPrice ? selectedPrice.product_price_id : product.product_price_id;
        var price_qty = selectedPrice ? selectedPrice.price_qty : product.price_qty;
        var price_unit_name = selectedPrice ? selectedPrice.price_unit_name : product.price_unit_name;
        var qty = 1;
        var per_mrp_price = selectedPrice ? selectedPrice.price_mrp : product.price_mrp;
        var total_mrp_price = (qty * per_mrp_price);
        var per_discount = selectedPrice ? selectedPrice.price_discount : product.price_discount;
        var total_discount = (qty * per_discount);
        var final_per_price = selectedPrice ? selectedPrice.price_final_price : product.price_final_price;
        var final_total_price = (qty * final_per_price);

        const productData = {
            user_id: user_id,
            sessionkey: session_key,
            product_id: product_id,
            product_price_id: product_price_id,
            price_qty: price_qty,
            price_unit_name: price_unit_name,
            qty: qty,
            per_mrp_price: per_mrp_price,
            total_mrp_price: total_mrp_price,
            per_discount: per_discount,
            total_discount: total_discount,
            final_per_price: final_per_price,
            final_total_price: final_total_price
        };
        //console.log('addtocarthi',productData);

        if(in_stockstatus==='INSTOCK'){
            const response = await cartService.addtocart(productData);
    
            // Assuming your API returns a success flag or some response indicating successful cart addition
            if (response.status==true) {
            alert(response.message);
            // Handle success, e.g., show a success message or redirect to the cart page
            window.location.reload();
            } else {
            alert(response.message);
            // Handle failure, e.g., show an error message
            }
        }else{
            alert('Product is out of stock');
        }
    } catch (error) {
        console.error('Error adding product to cart:', error);
    }
  };

  const handlePriceChange = (event, product) => {
    const selectedPriceId = event.target.value;
    //alert(selectedPriceId);
    const selectedPrice = product.pricesArr.find(price => price.product_price_id === selectedPriceId);
    setSelectedProductPrice(selectedPrice);
    setSelectedPrices(prevState => ({
        ...prevState,
        [product.id]: selectedPrice
    }));
  };



//   return (
//     <div>
//       <h2>Product List for {category}</h2>
//       {products.map((product) => (
//         <div key={product.id}>{product.product_name}</div>
//       ))}
//     </div>
//   );

// Calculate the index of the last product on the current page
const indexOfLastProduct = currentPage * productsPerPage;
// Calculate the index of the first product on the current page
const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
// Get the current products to display based on the current page
const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

// Change the current page
const handlePageChange = (event, page) => {
  setCurrentPage(page);
};

return (
    <>
        <section className='Plant_items mt-3'>
            <div className='container-lg'>
                <div className='row gx-1 gy-3'>
                    {
                        currentProducts.map((element,i) => {
                            return (
                                <>
                                    <div className='col-lg-3 col-md-3 cds'>
                                        <div className="card" style={{ width: '95%', margin: 'auto' }}>
                                            <NavLink to={`/product-info/${element.id}`}>
                                                <img key={i} src={element.image} className="card-img-top" alt={element.alt} onClick={()=> send1(element)} />
                                            </NavLink>
                                            <div className="card-body">
                                                <p className="card-title"><NavLink to={`/product-info/${element.id}`}>{element.product_name} - {element.products_units_all}</NavLink></p>
                                                <Stack spacing={1}>
                                                    <Rating name='half-rating' defaultValue={4.5} precision={0.5} size='small'/>
                                                </Stack>
                                                <Stack spacing={2}>
                                                    {/* {element.price_qty} {element.price_unit_name} */}
                                                    <div className='pb-2'>
                                                        <select class="form-select" aria-label="Default select example" onChange={(event) => handlePriceChange(event, element)}>
                                                            {/* <option value={`${element.price_qty} ${element.price_unit_name}`}>{element.price_qty} {element.price_unit_name}</option> */}
                                                            {element.pricesArr.map(price => (
                                                                <option key={price.product_price_id} value={`${price.product_price_id}`}>
                                                                {`${price.price_qty} ${price.price_unit_name}`}
                                                                </option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                </Stack>
                                                <div className='card-bottom'>
                                                    {/* <p><b>&#8377; {element.price_final_price}</b></p> */}
                                                    <p><b>&#8377; {selectedPrices[element.id]?.price_final_price || element.price_final_price}</b></p>

                                                    {isLoggedIn ? (
                                                        <RiShoppingCartFill className='cart_icon bg-success text-light p-1 rounded' title='Add To Cart1' onClick={()=> addToCart(element,selectedPrices[element.id])} />
                                                    ) : (
                                                        <NavLink to={`/login/product/${element.id}`}>
                                                            <RiShoppingCartFill
                                                            className='cart_icon bg-success text-light p-1 rounded'
                                                            title='Add To Cart'
                                                            />
                                                        </NavLink>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </section>
        {products.length > 0 ? ( // Add this condition to check if categoryDetails is available
            <>
                <Stack spacing={2}  className='mt-4 mb-4'>
                    {/* <Pagination count={10} size="small" shape="rounded" /> */}
                    <Pagination
                        count={Math.ceil(products.length / productsPerPage)} // Calculate the total number of pages
                        page={currentPage} // Set the current page
                        onChange={handlePageChange} // Handle page changes
                        size="small"
                        shape="rounded"
                        />
                </Stack>
            </>
        ) : (
            <Stack spacing={2}  className='mt-4 mb-4'></Stack>
        )}
    </>
    )
}

export default ProductList;
