import React, { useEffect, useState } from 'react';
import '../assets/css/style.css';
import { Link, NavLink} from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
/* Images */
import brandLogo from '../assets/img/logo/liebigs_logo-163x62.webp'
import emptycart from '../assets/icons/empty-cart.png';
/* icon */
import { Avatar, Badge } from '@mui/material';
import { BsCart4 } from 'react-icons/bs';
import Sidebarmenu from './Sidebarmenu';
import { RiCloseFill } from 'react-icons/ri';
import { BsArrowLeftShort} from 'react-icons/bs';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { BiUser } from 'react-icons/bi';
import { IoHome } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { MdLocalLibrary } from 'react-icons/md';
import { GiRotaryPhone } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import defaultuserlogo from '../assets/img/users/default.png';
import LogoutService from '../services/LogoutService';
import CartService from '../services/CartService';

import SearchIcon from '@mui/icons-material/Search';
import { CgHashtag } from "react-icons/cg";
import { AiOutlineArrowLeft } from "react-icons/ai";

import ProductService from '../services/ProductService';

function Header() {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cartData, setCartData] = useState([]);
    const [quantity, setQuantity] = useState(0);

    const [searchKey, setSearchKey] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    
    

    const handleSearchChange = async (event) => {
        const searchValue = event.target.value;
        setSearchKey(searchValue);
        try {
            const productService = new ProductService();
            const results = await productService.fetchSearchProductsByProductName(searchValue);
            setSearchResults(results);
        } catch (error) {
            console.error('Error searching products:', error);
        }
    };

    
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const getData = useSelector((state) => state.cartreducer.carts);
    //const cartData = useSelector((state) => state.cartReducer.cartData);
    //const cartData = useSelector((state) => state.cartReducer.cartData);

    //console.log(getData);
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    //console.log('isLoggedIn',isLoggedIn);
    

    const color = {
        color : 'red'
    }

    // const handleCartClick = () => {
    //     if (isLoggedIn) {
    //         // If the user is logged in, redirect to the cart page
    //         history.push('/cart');
    //     } else {
    //         // If the user is not logged in, redirect to the login page
    //         history.push('/login'); // Replace 'login' with the path to your login page
    //     }
    // };
    const handleLogout = () => {
        // Get session_key and userid from localStorage
        const sessionKey = localStorage.getItem('session_key');
        const userId = localStorage.getItem('userid');
    
        // Call the logout API service passing session_key and userid
        const logoutServiceInstance = new LogoutService();
        logoutServiceInstance
          .logout(sessionKey, userId)
          .then((response) => {
            // Clear user data and set isLoggedIn to false in local storage
            localStorage.removeItem('userid');
            localStorage.removeItem('fullname');
            localStorage.removeItem('email');
            localStorage.removeItem('phonenumber');
            localStorage.removeItem('session_key');
            localStorage.removeItem('userlogo');
            localStorage.removeItem('isLoggedIn');
    
            // Redirect to the home page after logout
            navigate('/login');
          })
          .catch((error) => {
            // Handle API error if needed
            console.error('Logout failed:', error);
            // Even if there's an error, you can still clear user data and redirect if needed
            localStorage.removeItem('userid');
            localStorage.removeItem('fullname');
            localStorage.removeItem('email');
            localStorage.removeItem('phonenumber');
            localStorage.removeItem('session_key');
            localStorage.removeItem('userlogo');
            localStorage.removeItem('isLoggedIn');
            navigate('/login');
          });
      };
    
    
    useEffect(() => {
        // Fetch cart data using CartDataService when the component mounts
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
    
        const cartServiceInstance = new CartService();

        const sendCartData = {
            user_id: user_id,
            sessionkey: session_key,
            //limit: 3
          };

          cartServiceInstance
          .cartlist(sendCartData)
          .then((data) => {
            setCartData(data);
          })
          .catch((error) => {
            console.error('Error fetching cart data:', error);
          });
      }, []);
      //console.log('cartData',cartData.data);
      //console.log('cartData',cartData.data?.length);

    const handleQuantityIncrease = async (product) => {
        setQuantity((prevQuantity) => Math.max(prevQuantity + 1, 1));
        //console.log('productArrrrr',product);

        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.product_id;
            var in_stockstatus = product.in_stock;
            var product_price_id = product.product_price_id;
            var price_qty = product.price_qty;
            var price_unit_name = product.price_unit_name;
            var qty = 1;
            var per_mrp_price = product.per_mrp_price;
            var total_mrp_price = (qty * product.per_mrp_price);
            var per_discount = product.discount_price;
            var total_discount = (qty * product.discount_price);
            var final_per_price = product.final_per_price;
            var final_total_price = (qty * product.final_per_price);
            
            const productData = {
              user_id: user_id,
              sessionkey: session_key,
              product_id: product_id,
              product_price_id: product_price_id,
              price_qty: price_qty,
              price_unit_name: price_unit_name,
              qty: qty,
              per_mrp_price: per_mrp_price,
              total_mrp_price: total_mrp_price,
              per_discount: per_discount,
              total_discount: total_discount,
              final_per_price: final_per_price,
              final_total_price: final_total_price
            };
            if(in_stockstatus==='INSTOCK'){
                const response = await cartService.addtocart(productData);
        
                // Assuming your API returns a success flag or some response indicating successful cart addition
                if (response.status==true) {
                //alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                //window.location.reload();
                
                    const cartServiceInstance = new CartService();

                    const sendCartData = {
                        user_id: user_id,
                        sessionkey: session_key,
                        limit: ''
                    };

                    cartServiceInstance
                    .cartlist(sendCartData)
                    .then((data) => {
                        setCartData(data);
                    })
                    .catch((error) => {
                        console.error('Error fetching cart data:', error);
                    });
                } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
                }
            }else{
                alert('Product is out of stock');
            }
          } catch (error) {
            console.error('Error adding product to cart:', error);
          }

    };

    const handleQuantityDecrease = async (product) => {
        setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
        //console.log('productArrrrr',product);

        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.product_id;
            var cart_id = product.cart_id;
            var product_price_id = product.product_price_id;
            var price_qty = product.price_qty;
            var price_unit_name = product.price_unit_name;
            
            const productData = {
              user_id: user_id,
              sessionkey: session_key,
              product_id: product_id,
              cart_id: cart_id,
              product_price_id: product_price_id,
              price_qty: price_qty,
              price_unit_name: price_unit_name
            };
            const response = await cartService.removeonetocart(productData);
      
            // Assuming your API returns a success flag or some response indicating successful cart addition
            if (response.status==true) {
              //alert(response.message);
              // Handle success, e.g., show a success message or redirect to the cart page
              //window.location.reload();
              
                const cartServiceInstance = new CartService();

                const sendCartData = {
                    user_id: user_id,
                    sessionkey: session_key,
                    limit: ''
                };

                cartServiceInstance
                .cartlist(sendCartData)
                .then((data) => {
                    setCartData(data);
                })
                .catch((error) => {
                    console.error('Error fetching cart data:', error);
                });
            } else {
              alert(response.message);
              // Handle failure, e.g., show an error message
            }
          } catch (error) {
            console.error('Error adding product to cart:', error);
          }

    };

    const removeToCart = async (product) => {
    //console.log('productArr',product);
    try {
        const cartService = new CartService();
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
        var product_id = product.product_id;
        var cart_id = product.cart_id;
        
        const productData = {
            user_id: user_id,
            sessionkey: session_key,
            product_id: product_id,
            cart_id: cart_id
        };
        const response = await cartService.deletetocart(productData);
    
        // Assuming your API returns a success flag or some response indicating successful cart addition
        if (response.status==true) {
            alert(response.message);
            // Handle success, e.g., show a success message or redirect to the cart page
            // After successful removal, fetch the updated cart data and update the state
            const cartServiceInstance = new CartService();
            const sendCartData = {
            user_id: user_id,
            sessionkey: session_key,
            limit: 2
            };
            const updatedCartData = await cartServiceInstance.cartlist(sendCartData);
            setCartData(updatedCartData);

        } else {
            alert(response.message);
            // Handle failure, e.g., show an error message
        }
    } catch (error) {
        console.error('Error remove product to cart:', error);
    }
    };

    // Calculate the total price and total discount
    const totalMRPPrice = cartData.data?.reduce((total, item) => total + parseFloat(item.total_mrp_price), 0) || 0;
    const totalDiscount = cartData.data?.reduce((total, item) => total + parseFloat(item.total_discount), 0) || 0;
    const totalAmount = cartData.data?.reduce((total, item) => total + parseFloat(item.final_total_price), 0) || 0;


    return (
        <>

            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">

                    <div className="container-fluid">

                        <Link className="navbar-brand" to="/">
                            <img src={brandLogo} alt='Brand Logo' width="70%" />
                        </Link>
                        {/*  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
                        <div className="collapse navbar-collapse d-lg-block d-none" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                               
                                 <li className="nav-item" title='Home'>
                                    <NavLink className="nav-link navs" aria-current="page" to="/"><IoHome className='cart_icon'/></NavLink>
                                </li>
                                <li className="nav-item" title='About Us'>
                                    <NavLink className="nav-link navs" to="/about-us"><MdLocalLibrary className='cart_icon' /></NavLink>
                                </li>
                                <li className="nav-item" title='Contact'>
                                    <NavLink className="nav-link navs" to="/contact-us"><GiRotaryPhone className='cart_icon' /></NavLink>
                                </li>
                            </ul> 
                        </div> 

                        <div>
                            <IconButton size="large" aria-label="search" color="inherit" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <SearchIcon/>
                            </IconButton>
                        </div>

                        <div className='d-flex align-item-center' style={{gap:'15px'}}>
                            <Badge badgeContent={isLoggedIn == 1 ? cartData.data?.length : 0} color="error" >
                                {isLoggedIn && isLoggedIn==1 ? (
                                    <BsCart4 className='cart_icon'
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    style={{color:'green'}}
                                />
                                ):(
                                    <Link to="/login"> 
                                        <BsCart4
                                            className='cart_icon'
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            style={{color:'green'}}
                                        />
                                    </Link>
                                )}
                            </Badge>
                            {isLoggedIn && isLoggedIn==1 && (
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {cartData.data?.length ? (
                                        <div className="card-details p-2">
                                            <RiCloseFill onClick={handleClose} className="close_btn" />
                                            <hr />
                                            {cartData.data.map((e) => {
                                                return (
                                                    <>
                                                        <div className="row p-1">
                                                            <div className="col-lg-3 col-3">
                                                                <div>
                                                                    <NavLink to={`/product-info/${e.product_id}`}>
                                                                        <img src={e.image} alt="sss" className="img-fluid" />
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-8">
                                                                <div>
                                                                    <h6>
                                                                        <NavLink to={`/product-info/${e.product_id}`}>{e.product_name}</NavLink>
                                                                    </h6>
                                                                    <div className='d-block d-lg-flex' style={{display:'flex',alignItems:'baseline',gap:'15px'}}>
                                                                        <div className='cartDropdown_mrp'>
                                                                           <strong>MRP : </strong> ₹{e.final_per_price}
                                                                        </div>
                                                                        <div style={{fontSize:'14px'}}>
                                                                           <strong>Net Weight : </strong> {e.price_qty}{e.price_unit_name}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                   <p style={{fontSize:'14px'}}>
                                                                        <strong>Qty : </strong> {e.qty}
                                                                    </p>
                                                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic outlined example">
                                                                        <button type="button" class="btn btn-outline-primary" onClick={() => handleQuantityDecrease(e)}>-</button>
                                                                        <button type="button" class="btn btn-outline-primary">{e.qty}</button>
                                                                        <button type="button" class="btn btn-outline-primary" onClick={() => handleQuantityIncrease(e)}>+</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1">
                                                                <div className='d-flex justify-content-end'>
                                                                    <IconButton aria-label="delete" onClick={()=> removeToCart(e)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                );
                                            })}
                                            <div className="cartDropdown-total-info">
                                                <h5>
                                                    Total :<span> ₹ {totalAmount.toFixed(2)}</span>
                                                </h5>
                                                <NavLink to="/cart">
                                                    <button className="btn btn-danger shadow rounded" onClick={handleClose}>
                                                        View Cart
                                                    </button>
                                                </NavLink>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="cart_area">
                                            <RiCloseFill onClick={handleClose} className="close_btn" />
                                            <div className="cart_emp">
                                                <img src={emptycart} alt="empty cart" width="180" />
                                                <p className="emp_cart_text">Your cart is empty</p>
                                            </div>
                                        </div>
                                    )}
                                </Menu>
                            )}
                            {isLoggedIn && isLoggedIn==1 && (
                                <Avatar
                                    src={defaultuserlogo}
                                    alt="cds"
                                    className="user_icon ms-0"
                                    onClick={handleClick1}
                                    sx={{ ml: 2 }}
                                    aria-controls={open1 ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? 'true' : undefined}
                                />
                            )}
                            {isLoggedIn && isLoggedIn==1 && (
                                <Menu
                                    anchorEl={anchorEl1}
                                    id="account-menu"
                                    open={open1}
                                    onClose={handleClose1}
                                    onClick={handleClose1}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <NavLink to="/profile">
                                        <MenuItem onClick={handleClose1}>
                                            <ListItemIcon>
                                                <BiUser />
                                            </ListItemIcon>
                                            My Account
                                        </MenuItem>
                                    </NavLink>
                                    <NavLink to="/order">
                                        <MenuItem onClick={handleClose1}>
                                            <ListItemIcon>
                                                <AiOutlineUnorderedList />
                                            </ListItemIcon>
                                            My Order
                                        </MenuItem>
                                    </NavLink>
                                    {/* <NavLink to="/track-order">
                                        <MenuItem onClick={handleClose1}>
                                            <ListItemIcon>
                                                <TiLocationOutline />
                                            </ListItemIcon>
                                            Order Tracking
                                        </MenuItem>
                                    </NavLink> */}
                                    <NavLink to="#" onClick={handleLogout}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <Logout fontSize="small" />
                                            </ListItemIcon>
                                            Sign out
                                        </MenuItem>
                                    </NavLink>
                                </Menu>
                            )}
                            <ProSidebarProvider>
                                <Sidebarmenu />
                            </ProSidebarProvider>
                        </div>
                    </div>
                </nav>


            </header>
            {isLoggedIn ? (
                <NavLink to="bulk-order/11" className='bulk-order'>Bulk Order <BsArrowLeftShort className='bulk'/></NavLink>
            
            ) : (
                <NavLink to="/login/bulkorder" className='bulk-order'>Bulk Order <BsArrowLeftShort className='bulk'/></NavLink>
            )}

            {/* Search Model */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-header">
                    <form class="d-flex" style={{width:'100%'}}>
                        {/* <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> */}
                        <input
                        className="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchKey}
                        onChange={handleSearchChange} // Call handleSearchChange when input value changes
                    />
                    </form>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <ul className='p-0'>
                                {searchResults && searchResults.length > 0 && (
                                searchResults.map((result) => (
                                <li key={result.id}>
                                    <NavLink to={`/product-info/${result.id}`}>
                                        <div className='search-div'>
                                            <div className='hashtag'>
                                                <CgHashtag />
                                            </div>
                                            <div className='search-link'>
                                                <p className='mb-1'>{result.product_name} - {result.products_units_all}</p>
                                                <p className='mb-0'>{result.category}</p>
                                            </div>
                                            <div className='arrow'>
                                                <AiOutlineArrowLeft />
                                            </div>
                                        </div>
                                    </NavLink>
                                </li>
                                ))
                                )}
                                {/* <li>
                                    <NavLink to="/search-item">
                                        <div className='search-div'>
                                            <div className='hashtag'>
                                                <CgHashtag />
                                            </div>
                                            <div className='search-link'>
                                                <p className='mb-1'>BOOSTER 3 - Flower booster for cucumber and watermelon - 15 ML</p>
                                                <p className='mb-0'>Agrochem</p>
                                            </div>
                                            <div className='arrow'>
                                                <AiOutlineArrowLeft />
                                            </div>
                                        </div>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* end */}

                    
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Header