import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { BiCategoryAlt, BiAlignRight, BiPhoneCall } from 'react-icons/bi';
import { SlClose } from 'react-icons/sl';
import { NavLink } from 'react-router-dom';
import { HiOutlineRectangleGroup } from 'react-icons/hi2';
import { RiPagesFill, RiPlantFill } from 'react-icons/ri';
import { FaBloggerB, FaUserCircle } from 'react-icons/fa';
import { AiTwotoneHome } from 'react-icons/ai';
import { TiShoppingCart } from 'react-icons/ti';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';

// Import the productService
import ProductService from '../services/ProductService';


function Sidebarmenu() {

  const { collapseSidebar } = useProSidebar();
  const getData = useSelector((state) => state.cartreducer.carts);
  
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  // State to store the product categories
  const [categories, setCategories] = useState([]);

  // Fetch product categories on component mount
  useEffect(() => {
    const productService = new ProductService();

    productService
      .fetchProductCategories()
      .then((data) => {
        setCategories(data.data); // Assuming the API response has a "data" property
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

    return (
        <>
            <main>
                <BiAlignRight className='sidebar_open_button' onClick={() => collapseSidebar()} style={{ cursor: 'pointer' }} />
            </main>
            <div className="overlay" onClick={() => collapseSidebar()} style={{ cursor: 'pointer' }}></div>
            <div className='sidebarmenu'>

                <Sidebar defaultCollapsed={true}>
                <SlClose
                    className="sidebar_close_button"
                    onClick={() => collapseSidebar()}
                    style={{ cursor: 'pointer' }}
                />
                <Menu>
                    <NavLink to="/">
                    <MenuItem
                        icon={<AiTwotoneHome className="heart_icon" />}
                        onClick={() => collapseSidebar()}
                    >
                        Home
                    </MenuItem>
                    </NavLink>
                    <SubMenu label="Products" icon={<BiCategoryAlt className="heart_icon" />}>
                    {categories.map((category) => (
                        <NavLink key={category.id} to={`/products/${category.categorykey}`}>
                        <MenuItem icon={<RiPlantFill />} onClick={() => collapseSidebar()}>
                            {category.category}
                        </MenuItem>
                        </NavLink>
                    ))}
                    </SubMenu>
                    <NavLink to="/blog">
                    <MenuItem
                        icon={<FaBloggerB className="heart_icon" />}
                        onClick={() => collapseSidebar()}
                    >
                        Blogs
                    </MenuItem>
                    </NavLink>
                    <NavLink to="/about-us">
                    <MenuItem
                        icon={<RiPagesFill className="heart_icon" />}
                        onClick={() => collapseSidebar()}
                    >
                        About Us
                    </MenuItem>
                    </NavLink>
                    <NavLink to="/contact-us">
                    <MenuItem
                        icon={<BiPhoneCall className="heart_icon" />}
                        onClick={() => collapseSidebar()}
                    >
                        Contact Us
                    </MenuItem>
                    </NavLink>
                    {isLoggedIn ? (
                    <NavLink to="bulk-order/11">
                    <MenuItem
                        icon={<HiOutlineRectangleGroup className="heart_icon" />}
                        onClick={() => collapseSidebar()}
                    >
                        Bulk Order
                    </MenuItem>
                    </NavLink>
                    ) : (
                        <NavLink to="/login/bulkorder">
                        <MenuItem
                            icon={<HiOutlineRectangleGroup className="heart_icon" />}
                            onClick={() => collapseSidebar()}
                        >
                            Bulk Order
                        </MenuItem>
                        </NavLink>
                    )}
                    <div className="sidebar_footer">
                    {isLoggedIn && isLoggedIn==1 ? (
                        <NavLink to="/cart">
                            <MenuItem
                            icon={
                                <Badge badgeContent={getData.length} color="primary">
                                <TiShoppingCart className="heart_icon" />
                                </Badge>
                            }
                            onClick={() => collapseSidebar()}
                            >
                            Add to cart
                            </MenuItem>
                        </NavLink>
                        
                    ) : (
                        <NavLink to="/login">
                            <MenuItem
                            icon={
                                <Badge badgeContent={getData.length} color="primary">
                                <TiShoppingCart className="heart_icon" />
                                </Badge>
                            }
                            onClick={() => collapseSidebar()}
                            >
                            Add to cart
                            </MenuItem>
                        </NavLink>
                    )}
                    {isLoggedIn && isLoggedIn==1 && (
                        <NavLink to="/profile">
                            <MenuItem
                            icon={<FaUserCircle className="heart_icon" />}
                            onClick={() => collapseSidebar()}
                            >
                            Account
                            </MenuItem>
                        </NavLink>
                    )}
                    </div>
                </Menu>
                </Sidebar>



            </div>
        </>
    )
}

export default Sidebarmenu