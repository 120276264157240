import React from 'react';
import img1 from '../assets/img/product/p2.jpg';

import shape1 from '../assets/img/bg/shape1.png';
import shape2 from '../assets/img/bg/shape2.png';


function Aboutus() {
  return (
    <>
      <section className='about-us'>
        <div className="container">
          <div className="row gy-0">
            <div className="col-lg-6 d-flex justify-content-center flex-column">
              <div className='l_info1 p-3'>
                <h2>Welcome to <span className='display-5'>Liebigs</span><span className='display-2' style={{ color: '#039701' }}>.</span></h2>
                <p>
                    We at Liebigs are firmly committed towards bringing changes in modern agriculture through our products & services that are designed to benefit  farmers and improve their standard of living. We aim to create value through innovation, growth and generating income for them.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <img src={img1} alt="img1" className='img-fluid' width='580px' />
              </div>
            </div>
          </div>
          <div>
            <div className='mb-3'>
              <h2>Our <span className='display-5'>Divisons</span><span className='display-2' style={{ color: '#039701' }}>.</span></h2>
              <p>
                  At Liebigs Agro Chem Pvt Ltd, we offer an extensive array of products designed to cater to the diverse needs of farmers, wholesalers, distributors, and home gardeners:
              </p>
            </div>
            <div className="row gy-3">
              <div className="col-lg-4 col-md-6">
                <div className='about_c1 fertilizer'>
                  <h5>Fertilizers</h5>
                  <p>
                    Our scientifically formulated fertilizers are tailored to enhance soil health and crop productivity, ensuring bountiful harvests.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className='about_c1 pesticides'>
                  <h5>Pesticides</h5>
                  <p>
                    Effectively protect your crops from pests and diseases with our safe and efficient pesticide solutions.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className='about_c1 seeds'>
                  <h5>Seeds</h5>
                  <p>
                    Whether you're growing vegetables or cultivating stunning flowers, our premium-quality seeds are your key to successful cultivation.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className='about_c1 nursery'>
                  <h5>Nursery Division</h5>
                  <p>
                    Explore our nursery division, where you can find a wide variety of healthy and vibrant plants to kickstart your gardening projects.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className='about_c1 horticulture'>
                  <h5>Horticulture Division</h5>
                  <p>
                    Our horticulture division is dedicated to providing expert guidance and products for ornamental and landscape gardening.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-3 mb-3'>
              <div>
                <h1>Our Mission<span className='display-2' style={{ color: '#039701' }}>.</span></h1>
                <div>
                  <p>As an agricultural company, we will strive hard to contribute sustainable growth in agriculture. At the same time we keep our focus on opportunities to drive our business towards significant growth, both quantitative and qualitative.</p>
                  <p>We maintain standard business practices and enhances value for customers, taking utmost care for safety,health and environment.</p>
                </div>
              </div>
              <div>
                <h1>Our Vision<span className='display-2' style={{ color: '#039701' }}>.</span></h1>
                <div>
                  
                  <p>Our focus is to establish ourselves as an ethical,responsible,respectable and dynamic company in the industry of crop care and its management.Our aim is to achieve commercial success through balancing economic growth with ecological and social responsibility. </p>
                </div>
              </div>
          </div>

          <div className='pt-5 pb-4'>
            <div className='mb-3'>
              <h1>About The <span className='display-5'>Founder</span><span className='display-2' style={{ color: '#039701' }}>.</span></h1>
            </div>
           <div className="row gy-3">
              <div className="col-lg-4">
              <div className='about_c1 p-4'>
                <p>
                In the heart of Liebigs Agro Chem Pvt Ltd's illustrious 37-year journey lies the indomitable spirit and visionary drive of our esteemed founder, Dr. Bimal Das Chowdhury. A distinguished scientist who once graced the halls of Hindusthan Fertilizer Corporation, Dr. Chowdhury harbored a dream that transcended personal gain – a dream deeply rooted in serving the farming community by providing essential inputs at affordable prices.
                </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='about_c1 p-4'>
                  <p>
                  Over the course of his illustrious career, Dr. Chowdhury blazed a trail of innovation, introducing approximately 25 to 30 revolutionary products that have redefined farming practices. From cutting aids to booster segments, and from Sudha Germinaid to Flash, his inventions have not only addressed the pressing challenges faced by farmers but have also set new standards of excellence in agriculture.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='about_c1 p-4'>
                  <p>
                  Though Dr. Bimal Das Chowdhury left is on February 5th, 2015, his legacy lives on through Liebigs Agro Chem Pvt by his most eligible son Dr. Bhaskar Chowdhury. He is also a renowned veterinarian Doctor. At present he is our Managing Director. His unwavering dedication to the welfare of farmers continues to inspire us.
                  </p>
                </div>
               {/*  <SocialLink /> */}
              </div>
            </div>
            <div className='about_video'>
              <div className='yt-frame'>
                <iframe className='d-block m-auto' width="90%" height="400" src="https://www.youtube.com/embed/HIo89xD---E" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <img src={shape1} alt="safdv" className='shape1' />
        <img src={shape2} alt="safdv" className='shape2' />
      </section>
    </>
  )
}

export default Aboutus