import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { NavLink } from 'react-router-dom';
import { IoCalendarOutline } from 'react-icons/io5';
import BlogService from '../services/BlogService';

function Blog() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6); // Set the number of items per page

    useEffect(() => {
        // Fetch data when the component mounts
        const service = new BlogService();
        service.fetchBlogsAll()
            .then((data) => setBlogPosts(data))
            .catch((error) => console.error('Error fetching blog posts:', error));
    }, []);

    const title32 = {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    };
    const desc42 = {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    };

    // Calculate the index of the last post for the current page
    const indexOfLastPost = currentPage * postsPerPage;
    // Calculate the index of the first post for the current page
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // Get the current page's posts
    const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

    // Handle page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <section>
                <div className="pt-3 pb-3">
                    <div className="container">
                        <div className="pb-3">
                            <h1 className="text-center">Blog Posts</h1>
                        </div>
                        {currentPosts.length === 0 ? (
                            <p>No blog posts available.</p>
                        ) : (
                            <>
                                <div className="row gy-3">
                                    {currentPosts.map((post) => (
                                        <div key={post.id} className="col-lg-4 col-md-6">
                                            <div>
                                                <div className="card" style={{ width: '90%' }}>
                                                    <NavLink to={`/blog-details/${post.blog_url_title}`}>
                                                        <img src={post.blog_logo} className="img-fluid" alt={post.blog_title_display} />
                                                    </NavLink>
                                                    <div className="p-3 bg-light" style={{ height: '100%' }}>
                                                        <p className="fw-bold"><IoCalendarOutline /> {post.posted_on}</p>
                                                        <NavLink to={`/blog-details/${post.blog_url_title}`}>
                                                            <h5 className="fw-bold fs-5" style={title32} title={post.blog_title_display}>
                                                                {post.blog_title_display}
                                                            </h5>
                                                        </NavLink>
                                                        <p className="card-text" style={desc42}>
                                                            {post.short_description_for_list}
                                                        </p>
                                                        <NavLink to={`/blog-details/${post.blog_url_title}`} className="btn btn-outline-primary">Read Blog</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <Pagination
                                        count={Math.ceil(blogPosts.length / postsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;
