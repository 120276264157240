class BlogService {
    
    async fetchBlogsAll() {
  
        //const categorykey = categorykey;
    
        //const replaced_category_name = category_name.replace(/\s+/g, "-");
        const limit = 'all';
        try {
          const response = await fetch('https://www.liebigsagrochem.com/api/blogs/listall/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                limit: limit,
            }),
          });
    
          if (response.ok) {
            const data = await response.json();
            //console.log('products',data);
            return data.data;
          } else {
            throw new Error('Failed to fetch blog list');
          }
        } catch (error) {
          throw new Error('Failed to fetch blog lis');
        }
    }

    async fetchBlogsByLimit(limits) {
  
        //const categorykey = categorykey;
    
        //const replaced_category_name = category_name.replace(/\s+/g, "-");
        const limit = limits;
        try {
          const response = await fetch('https://www.liebigsagrochem.com/api/blogs/listall/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                limit: limit,
            }),
          });
    
          if (response.ok) {
            const data = await response.json();
            //console.log('products',data);
            return data.data;
          } else {
            throw new Error('Failed to fetch blog list');
          }
        } catch (error) {
          throw new Error('Failed to fetch blog lis');
        }
    }

    async fetchBlodDetails(blogid) {
  
      const blog_id = blogid;
  
      //const replaced_category_name = category_name.replace(/\s+/g, "-");
  
      try {
        const response = await fetch('https://www.liebigsagrochem.com/api/blogs/details/v1/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            blog_id: blog_id,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('products',data);
          return data.data;
        } else {
          throw new Error('Failed to fetch blog details');
        }
      } catch (error) {
        throw new Error('Failed to fetch blog details');
      }
    } 
  
  }
  
  export default BlogService;
  
  
  