import axios from 'axios';
import CryptoJS from 'crypto-js';

class PaymentService {

  async payment_request(orderData) {
    try {
      const response = await axios.post(
        'https://www.liebigsagrochem.com/api/payment_request/v2/',
        // {
        //   amount: orderData.total_amount * 100, // Amount in paisa
        //   currency: 'INR',
        // }
        orderData
      );

      // Assuming your API returns a Razorpay order object
      const order = response.data;

      return order;
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
    }
  }

  async payment_response(transactionData) {
    //console.log('transactionData',transactionData);
    
    try {
      // Handle the Razorpay payment response on the server side
      // You may need to verify the payment using the Razorpay Signature
      // Refer to the Razorpay documentation for handling payment response: https://razorpay.com/docs/payment-gateway/webhooks/
      
      // Example: Verify Razorpay Signature
      const isSignatureValid = verifyRazorpaySignature(
        transactionData.razorpay_order_id,
        transactionData.razorpay_payment_id,
        transactionData.razorpay_signature
      );

      if (isSignatureValid) {

        const dataArr = {
          user_id: transactionData.user_id,
          sessionkey: transactionData.sessionkey,
          payment_request_id: transactionData.razorpay_order_id,
          payment_id: transactionData.razorpay_payment_id,
          payment_status: 'Credit'
        }
        const response = await axios.post(
          'https://www.liebigsagrochem.com/api/payment_response/v1/',
          dataArr
        );

        // Handle successful payment response
        //console.log('Payment successful:', transactionData);
        return response;
      } else {

        const dataArr = {
          user_id: transactionData.user_id,
          sessionkey: transactionData.sessionkey,
          payment_request_id: transactionData.razorpay_order_id,
          payment_id: transactionData.razorpay_payment_id,
          payment_status: 'Failed'
        }
        const response = await axios.post(
          'https://www.liebigsagrochem.com/api/payment_response/v1/',
          dataArr
        );

        throw new Error('Invalid Razorpay Signature');
      }
    } catch (error) {
      console.error('Error handling Razorpay payment response:', error);
      throw error;
    }
  }
}

// Function to verify Razorpay Signature
const verifyRazorpaySignature = (orderId, paymentId, signature) => {
  const secretKey = 'Q20snjp0c5kyXm7AzlH0d9tN'; //'GGe7NigCgiA4obe4BQX6VL2U'; // Replace with your actual Razorpay Secret Key
  const generatedSignature = hmac_sha256(orderId + '|' + paymentId, secretKey);

  return generatedSignature === signature;
};

// Function to generate HMAC-SHA256 hash
const hmac_sha256 = (data, key) => {
  return CryptoJS.HmacSHA256(data, key).toString(CryptoJS.enc.Hex);
};

export default PaymentService;
