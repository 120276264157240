import React, { useEffect, useState } from 'react'
import emptyCart from '../assets/icons/empty.png';
import { useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { Chip, Divider } from '@mui/material';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import CartService from '../services/CartService';
import PaymentService from '../services/PaymentService';



function Checkout() {

    const navigate = useNavigate(); // Initialize useNavigate hook

    const initialFormData = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        pincode: ''
    };

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false); // Add a state to track if the isLoggedIn check is complete
    const [cartData, setCartData] = useState({ data: [] });
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(null); // Add a state to track payment status
    const [paymentResponse, setPaymentResponse] = useState({});
    
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
        }
    }, [navigate]);

    useEffect(() => {
        // Fetch cart data using CartDataService when the component mounts
        const cartServiceInstance = new CartService();
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
    
        

        const sendCartData = {
            user_id: user_id,
            sessionkey: session_key,
            limit: ''
          };

          cartServiceInstance
          .cartlist(sendCartData)
          .then((data) => {
            setCartData(data);
          })
          .catch((error) => {
            console.error('Error fetching cart data:', error);
          });
      }, []);

    // Render a loading indicator while the isLoggedIn check is in progress
    if (!isLoggedInChecked) {
        return <div>Loading...</div>;
    }

    // Calculate the total price and total discount if cartData.data is not null
    const totalPrice = cartData.data ? cartData.data.reduce((total, item) => total + parseFloat(item.total_mrp_price), 0)   : 0;
    const totalDiscount = cartData.data ? cartData.data.reduce((total, item) => total + parseFloat(item.total_discount), 0) : 0;
    const totalAmount = cartData.data ? cartData.data.reduce((total, item) => total + parseFloat(item.final_total_price), 0) : 0;

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePlaceOrder = () => {
        //e.preventDefault();
        
        // Get an array of cart IDs from the cartData
        const cartIds = cartData.data.map((item) => item.cart_id);
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
        // Set the cart_id[] array and totalAmount in the formData
        const updatedFormData = {
            ...formData,
            'cart_id': cartIds,
            total_amount: totalAmount.toFixed(2), // Include the total amount with 2 decimal places
            user_id: user_id,
            sessionkey: session_key 
        };

        // Call the handleSubmit function to handle form submission
        //console.log('updatedFormData', updatedFormData);

        handleSubmit(updatedFormData);

      };
    
      

    //   const createPaymentRequest = async (orderData) => {
    //     try {
    //     //   const response = await axios.post(
    //     //     `${API_BASE_URL}payment_requests/`,
    //     //     {
    //     //       purpose: 'Order Payment',
    //     //       amount: orderData.total_amount,
    //     //       buyer_name: orderData.first_name,
    //     //       email: orderData.email,
    //     //       phone: orderData.phone,
    //     //       send_email: true,
    //     //       redirect_url: 'YOUR_REDIRECT_URL_AFTER_PAYMENT',
    //     //       webhook: 'YOUR_WEBHOOK_URL',
    //     //     },
    //     //     {
    //     //       auth: {
    //     //         username: API_KEY,
    //     //         password: AUTH_TOKEN,
    //     //       },
    //     //     }
    //     //   );
        
    //     //console.log('orderData111111111111111',orderData);
    //         const PaymentServiceInstance = new PaymentService();
    //         const response = await PaymentServiceInstance.payment_request(orderData);

    //         // Handle the response from the API, e.g., show success message, redirect to login page, etc.
    //         console.log('Payment successful:', response);
    //         return response;
            
          
    //     } catch (error) {
    //       console.error('Error creating payment request:', error);
    //     }
    //   };
      
      
      
    const handleSubmit = async (updatedFormData) => {
        //e.preventDefault();
        const validationErrors = validateFormData(updatedFormData);
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length === 0) {
            try {
                //console.log('formData', updatedFormData);
                // // Rest of the code remains the same
                // // Call the function to create the payment request
                // const response = await createPaymentRequest(updatedFormData);
                
                
                // setPaymentResponse(response);
                // if (response.status && response.status === true) {
                //     // Payment request created successfully
                //     // Redirect the user to the payment page
                //     console.log('responseArr',response)
                //     window.location = response.data.payment_request.longurl;
                //   } else {
                //     // Payment request creation failed
                //     setPaymentStatus('error');
                //   }

                const PaymentServiceInstance = new PaymentService();
                const response = await PaymentServiceInstance.payment_request(updatedFormData);
                //const response = 'k';
                // response.currency = 'INR';
                // response.id = 2;
                //console.log('response',response);
                //if (response.id) {
                    // Razorpay order created successfully
                    const roundedAmount = Math.round(response.data.amount);
                    //const razorpay_order_id = response.id;
                    //const razorpay_signature  = response.signature;
                    
                    const razorpayOptions = {
                      key: 'rzp_live_kw6g8k5zZTQCre', // Replace with your actual Razorpay Key ID
                      amount: roundedAmount,
                      currency: response.data.currency,
                      order_id: response.data.id,
                      name: 'Liebigs Agro chem',
                      description: 'We at Liebigs are firmly.',
                      image: 'https://www.liebigsagrochem.com/static/media/liebigs_logo-163x62.96f0c399e7767e48c048.webp', // Replace with your logo URL
                      handler: function (res) {
                        // Handle successful payment response
                        handlePaymentSuccess(res);
                      },
                      prefill: {
                        name: updatedFormData.first_name,
                        email: updatedFormData.email,
                        phone: updatedFormData.phone,
                      },
                      notes: {
                        address: updatedFormData.street1 + ' ' + updatedFormData.street2 + ', ' + updatedFormData.city + ', ' + updatedFormData.state + ', ' + updatedFormData.pincode,
                      },
                    };
          
                    const rzp = new window.Razorpay(razorpayOptions);
                    rzp.open();
                    // setTimeout(() => {
                    //     // Your code that uses window.Razorpay
                    //     const rzp = new window.Razorpay(razorpayOptions);
                    //     rzp.open();
                    // }, 1000); // Adjust the delay as needed
          
                // } else {
                //     // Razorpay order creation failed
                //     setPaymentStatus('error');
                // }

            } catch (error) {
                setErrorMessage('Checkout failed:', error);
                setSuccessMessage(''); // Clear any existing success message
                //console.error('Checkout failed:', error);
            }
        }
    };
    
    const handlePaymentSuccess = async (response) => {
        //console.log('response111',response);
        //const { id: orderId, signature } = response;

        //console.log('req.body',req.body);
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');

        const transactionData = {
          // Extract relevant data from the Razorpay response
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          user_id: user_id,
          sessionkey: session_key
        };
        //console.log('transactionData',transactionData);

        try {

          const PaymentServiceInstance = new PaymentService();
          const paymentResponse = await PaymentServiceInstance.payment_response(transactionData);
    
          // Handle the payment response as needed
          //console.log('Payment response:', paymentResponse);
          setPaymentResponse(paymentResponse);
          //console.log('paymentResponse',paymentResponse);

          if (paymentResponse.data.status==true) {
            //alert(paymentResponse.data.message);
            // Payment was successful, redirect or show success message
            setPaymentStatus('success');
            window.location.href="/payment-success";
          } else {
            // Payment failed, show error message
            //alert('error');
            setPaymentStatus('error');
            window.location.href="/payment-error";
          }
        } catch (error) {
          setErrorMessage('Error handling payment response:', error);
          console.error('Error handling payment response:', error);
        }
      };

    const validateFormData = (data) => {
        const errors = {};

        // Perform your validation checks here
        // For example, checking if required fields are filled, valid email format, etc.

        // Example validation for required fields
        if (!data.first_name) {
            errors.first_name = 'First Name is required';
        }

        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format';
        }

        if (!data.phone) {
            errors.phone = 'Phone Number is required';
        }

        if (!data.street1) {
            errors.street1 = 'Street is required';
        }

        if (!data.city) {
            errors.city = 'Town/City is required';
        }

        if (!data.state) {
            errors.state = 'State is required';
        }

        if (!data.pincode) {
            errors.pincode = 'Pincode is required';
        }

        
        return errors;
    };


    // Helper function to check for valid email format
    const isValidEmail = (email) => {
        // You can use a regular expression or any other method to validate email format
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

  return (
    <>
        <section className='ceckout'>
            <div className="container">
                <div>
                    <h1>Checkout</h1>
                    

                </div>
                
                {
                    cartData.data?.length
                        ?
                        <div className="row">
                                
                                <div className="col-lg-7">
                                    <hr/>
                                    <div className='checkout_form_details'>
                                        
                                            {/* {
                                            cartData.data.map((e) => {
                                                return (
                                                    <input type="hidden" name="cart_id[]" value={e.cart_id}  />
                                                    )
                                                })
                                            } */}
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div>
                                                        <label htmlFor="">First Name</label>
                                                        <br/>
                                                        <input type="text" name="first_name" value={formData.first_name} onChange={handleInputChange}  /><br/>
                                                        {errors.first_name && <span className="error">{errors.first_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <label htmlFor="">Last Name</label>
                                                        <br/>
                                                        <input type="text" name="last_name" value={formData.last_name} onChange={handleInputChange} /><br/>
                                                        {errors.last_name && <span className="error">{errors.last_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <label htmlFor="">Email Id</label>
                                                        <br/>
                                                        <input type="text" name="email" value={formData.email} onChange={handleInputChange} /><br/>
                                                        {errors.email && <span className="error">{errors.email}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <label htmlFor="">Phone Number</label>
                                                        <br/>
                                                        <input type="text" name="phone" value={formData.phone} onChange={handleInputChange} /><br/>
                                                        {errors.phone && <span className="error">{errors.phone}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <label htmlFor="">Company Name (Optional)</label>
                                                        <br/>
                                                        <input type="text" name="company_name" value={formData.company_name} onChange={handleInputChange} /><br/>
                                                        {errors.company_name && <span className="error">{errors.company_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <label htmlFor="">Street Address</label>
                                                        <br/>
                                                        <input type="text" name="street1" value={formData.street1} onChange={handleInputChange} /><br/>
                                                        {errors.street1 && <span className="error">{errors.street1}</span>}
                                                        <br/>
                                                        <input type="text" name="street2" value={formData.street2} onChange={handleInputChange} /><br/>
                                                        {errors.street2 && <span className="error">{errors.street2}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div>
                                                        <label htmlFor="">Town / City</label>
                                                        <br/>
                                                        <input type="text" name="city" value={formData.city} onChange={handleInputChange} /><br/>
                                                        {errors.city && <span className="error">{errors.city}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <label htmlFor="">State</label>
                                                        <br/>
                                                        <input type="text" name="state" value={formData.state} onChange={handleInputChange} /><br/>
                                                        {errors.state && <span className="error">{errors.state}</span>}
                                                        {/* <select name="state">
                                                            <option value="0">Select State</option>
                                                            <option value="0">State-1</option>
                                                            <option value="0">State-2</option>
                                                            <option value="0">State-3</option>
                                                        </select> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <label htmlFor="">Pin Code</label>
                                                        <br/>
                                                        <input type="text" name="pincode" value={formData.pincode} onChange={handleInputChange} /><br/>
                                                        {errors.pincode && <span className="error">{errors.pincode}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='mt-3 mb-3'>
                                                        {/* <button className='btn_1 me-3'>Reset</button> */}
                                                        <button className='btn_1' onClick={handlePlaceOrder}>Place Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    
                                </div>
                                
                                <div className="col-lg-5">
                                    <div className='pd_details'>
                                        <h3>Order Summary</h3>
                                        <hr/>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                            <h6>Price</h6>
                                            <h6>₹ {totalPrice.toFixed(2)}</h6>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                            <h6>Discount</h6>
                                            <h6>-₹ {totalDiscount.toFixed(2)}</h6>
                                            </div>
                                            {/* <div className="d-flex justify-content-between">
                                            <h6>Delivery Charge</h6>
                                            <h6>₹100</h6>
                                            </div> */}
                                            <hr/>
                                            <div className="d-flex justify-content-between">
                                            <h6>Total Amount</h6>
                                            <h6>₹ {totalAmount.toFixed(2)}</h6>
                                            </div>
                                            <hr/>
                                            <div className="d-flex justify-content-between">
                                            <h6 style={{color:'green'}}>You will Save ₹ {totalDiscount} in this order</h6>
                                                <button className='btn_1' onClick={handlePlaceOrder}>Place Order</button>

                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-12">
                                <img src={emptyCart} alt="empty-cart" className='img-fluid d-block m-auto' width="220" />
                                    <p className='fs-4 text-center'>No items found in cart</p>
                            </div>
                        </div>
                }
                    
            </div>
        </section>
    </>
  )
}

export default Checkout