class CategoryService {
      
    async fetchCategoryDetails(categorykey) {
  
      //const categorykey = categorykey;
  
      //const replaced_category_name = category_name.replace(/\s+/g, "-");
  
      try {
        const response = await fetch('https://www.liebigsagrochem.com/api/product_category/details/v1/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            categorykey: categorykey,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('cat',data);
          return data.data;
        } else {
          throw new Error('Failed to fetch categories details');
        }
      } catch (error) {
        throw new Error('Failed to fetch categories details');
      }
    }
  
  }
  
  export default CategoryService;
  
  
  