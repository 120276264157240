import { Rating, Stack } from '@mui/material'
import React from 'react'
import { RiShoppingCartFill } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'

function SearchItem() {
  return (
    <>
        <section className='pt-3 pb-3'>
            <div className="container">
                <div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="card" style={{ width: '95%', margin: 'auto' }}>
                                <NavLink to='/'>
                                    <img src={require('../assets/img/product/agro1.jpg')} className="card-img-top" alt="" />
                                </NavLink>
                                <div className="card-body">
                                    <p className="card-title"><NavLink to="/">Title</NavLink></p>
                                    
                                    <Stack spacing={1}>
                                        <Rating name='half-rating' defaultValue={4.5} precision={0.5} size='small'/>
                                    </Stack>
                                    <Stack spacing={1}>
                                        {/* {element.price_qty} {element.price_unit_name} */}
                                        <div className='pb-2'>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="">1 KG</option>
                                            </select>

                                        </div>
                                    </Stack>
                                    <div className='card-bottom'>
                                        <p><b>&#8377; 200</b></p>
                                        <NavLink to='/'>
                                            <RiShoppingCartFill
                                            className='cart_icon bg-success text-light p-1 rounded'
                                            title='Add To Cart'
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="card" style={{ width: '95%', margin: 'auto' }}>
                                <NavLink to='/'>
                                    <img src={require('../assets/img/product/agro1.jpg')} className="card-img-top" alt="" />
                                </NavLink>
                                <div className="card-body">
                                    <p className="card-title"><NavLink to="/">Title</NavLink></p>
                                    
                                    <Stack spacing={1}>
                                        <Rating name='half-rating' defaultValue={4.5} precision={0.5} size='small'/>
                                    </Stack>
                                    <Stack spacing={1}>
                                        {/* {element.price_qty} {element.price_unit_name} */}
                                        <div className='pb-2'>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="">1 KG</option>
                                            </select>

                                        </div>
                                    </Stack>
                                    <div className='card-bottom'>
                                        <p><b>&#8377; 200</b></p>
                                        <NavLink to='/'>
                                            <RiShoppingCartFill
                                            className='cart_icon bg-success text-light p-1 rounded'
                                            title='Add To Cart'
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default SearchItem