import React from 'react'

function PrivacyPolicy() {
  return (
    <>
        <section className='pt-3 pb-3'>
        <div className="container">
          <div>
            <h1 className='text-center'>Privacy Policy</h1>
          </div>
            <div className="row">
                <div className="col-lg-12">
                    <p>Welcome to <strong style={{fontSize:'3vh'}}>Liebigs Agro Chem.</strong></p>
                    <p><strong>Liebigs Agro Chem</strong> (“us”, “we”, or “our”) operates
                        <strong>https://www.liebigsagrochem.com/</strong> (hereinafter referred to as
                        <strong>“Service”</strong>).</p>
    
                    <p>Our Privacy Policy governs your visit to https://www.liebigsagrochem.com/, and explains how we collect,
                        safeguard and disclose information that results from your use of our Service.</p>
    
                    <p>We use your data to provide and improve Service. By using Service, you agree to the collection and
                        use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
                        the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
    
                    <p>Our Terms and Conditions (<strong>“Terms”</strong>) govern all use of our Service and together with
                        the Privacy Policy constitute your agreement with us (<strong>“agreement”</strong>).</p>
    
                    <p>&nbsp;</p>
    
                    <h4>2. Definitions</h4>
    
                    <p><strong>SERVICE </strong>means the https://www.liebigsagrochem.com/ website operated by Liebigs Agro Chem.</p>
    
                    <p><strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those
                        data (or from those and other information either in our possession or likely to come into our
                        possession).</p>
    
                    <p><strong>USAGE DATA </strong>is data collected automatically either generated by the use of Service or
                        from Service infrastructure itself (for example, the duration of a page visit).</p>
    
                    <p><strong>COOKIES</strong> are small files stored on your device (computer or mobile device).</p>
    
                    <p><strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in
                        common with other persons) determines the purposes for which and the manner in which any personal
                        data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller
                        of your data.</p>
    
                    <p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person who
                        processes the data on behalf of the Data Controller. We may use the services of various Service
                        Providers in order to process your data more effectively.</p>
    
                    <p><strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.</p>
    
                    <p><strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data
                        Subject, who is the subject of Personal Data.</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>3. Information Collection and Use</strong></h4>
    
                    <p>We collect several different types of information for various purposes to provide and improve our
                        Service to you.</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>4. Types of Data Collected</strong></h4>
    
                    <p><strong>Personal Data</strong><br/>
                        While using our Service, we may ask you to provide us with certain personally identifiable
                        information that can be used to contact or identify you (<strong>“Personal Data”</strong>).
                        Personally identifiable information may include, but is not limited to:</p>
    
                    <ol>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Address, Country, State, Postal code, City</li>
                        <li>Cookies and Usage Data</li>
                    </ol>
    
                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and
                        other information that may be of interest to you. You may opt out of receiving any, or all, of these
                        communications from us by following the unsubscribe link.</p>
    
                    <p><strong>Usage Data</strong><br/>
                        We may also collect information that your browser sends whenever you visit our Service or when you
                        access Service by or through any device (“<strong>Usage Data</strong>”).</p>
    
                    <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP
                        address), browser type, browser version, the pages of our Service that you visit, the time and date
                        of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    </p>
    
                    <p>When you access Service with a device, this Usage Data may include information such as the type of
                        device you use, your device unique ID, the IP address of your device, your device operating system,
                        the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>
    
                    <p><strong>Location Data</strong></p>
    
                    <p>We may use and store information about your location if you give us permission to do so
                        (“<strong>Location Data</strong>”). We use this data to provide features of our Service, to improve
                        and customize our Service.</p>
    
                    <p>You can enable or disable location services when you use our Service at any time by way of your
                        device settings.</p>
    
                    <p><strong>Tracking Cookies Data</strong></p>
    
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold
                        certain information.</p>
    
                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a website and stored on your device. Other tracking
                        technologies are also used such as beacons, tags and scripts to collect and track information and to
                        improve and analyze our Service.</p>
    
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
    
                    <p>Examples of Cookies we use:</p>
    
                    <ol>
                        <li><strong>Session Cookies :</strong> We use Session Cookies to operate our Service.</li>
                        <li><strong>Preference Cookies :</strong> We use Preference Cookies to remember your preferences and
                            various settings.</li>
                        <li><strong>Security Cookies :</strong> We use Security Cookies for security purposes.</li>
                        <li><strong>Advertising Cookies :</strong> Advertising Cookies are used to serve you with
                            advertisements that may be relevant to you and your interests.</li>
                    </ol>
    
                    <p><strong>Other Data</strong></p>
    
                    <p>While using our Service, we may also collect the following information: sex, age, date of birth,
                        place of birth, citizenship, registration at place of residence and actual address, telephone number
                        (work, mobile), details of documents on education, qualification, professional training, employment
                        agreements,&nbsp; information on bonuses and compensation, information on marital status, family
                        members, social security (or other taxpayer identification) number, office location and other data.
                    </p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>5. Use of Data</strong></h4>
    
                    <p>Liebigs Agro Chem uses the collected data for various purposes:</p>
    
                    <ol>
                        <li>to provide and maintain our Service;</li>
                        <li>to notify you about changes to our Service;</li>
                        <li>to allow you to participate in interactive features of our Service when you choose to do so;
                        </li>
                        <li>to provide customer support;</li>
                        <li>to gather analysis or valuable information so that we can improve our Service;</li>
                        <li>to monitor the usage of our Service;</li>
                        <li>to detect, prevent and address technical issues;</li>
                        <li>to fulfil any other purpose for which you provide it;</li>
                        <li>to carry out our obligations and enforce our rights arising from any contracts entered into
                            between you and us, including for billing and collection;</li>
                        <li>to provide you with notices about your account and/or subscription, including expiration and
                            renewal notices, email-instructions, etc.;</li>
                        <li>to provide you with news, special offers and general information about other goods, services and
                            events which we offer that are similar to those that you have already purchased or enquired
                            about unless you have opted not to receive such information;</li>
                        <li>in any other way we may describe when you provide the information;</li>
                        <li>for any other purpose with your consent.</li>
                    </ol>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>6. Retention of Data</strong></h4>
    
                    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this
                        Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
                        legal obligations (for example, if we are required to retain your data to comply with applicable
                        laws), resolve disputes, and enforce our legal agreements and policies.</p>
    
                    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
                        shorter period, except when this data is used to strengthen the security or to improve the
                        functionality of our</p>
    
                    <p>Service, or we are legally obligated to retain this data for longer time periods.</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>7. Transfer of Data</strong></h4>
    
                    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers
                        located outside of your state,country or other governmental jurisdiction where the data protection
                        laws may differ from those of your jurisdiction.</p>
    
                    <p>If you are located outside India and choose to provide information to us, please note that we
                        transfer the data, including Personal Data, to India and process it there.<br/>
                        Your consent to this Privacy Policy followed by your submission of such information represents your
                        agreement to that transfer.</p>
    
                    <p>Liebigs Agro Chem will take all the steps reasonably necessary to ensure that your data is
                        treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data
                        will take place to an organisation or a country unless there are adequate controls in place
                        including the security of your data and other personal information.</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>8. Disclosure of Data</strong></h4>
    
                    <p>We may disclose personal information that we collect, or you provide:</p>
    
                    <p style={{marginLeft:'40px'}}><strong>1. Business Transaction.</strong></p>
    
                    <p style={{marginLeft:'40px'}}>If we or our subsidiaries are involved in a merger, acquisition or asset
                        sale, your Personal Data may be transferred.</p>
    
                    <p style={{marginLeft:'40px'}}><strong>2. Other cases. We may disclose your information also:</strong></p>
    
                    <p style={{marginLeft:'80px'}}>2.1. to our subsidiaries and affiliates;<br/>
                        2.2. to contractors, service providers, and other third parties we use to support our business;<br/>
                        2.3. tofulfill the purpose for which you provide it;<br/>
                        2.4. for any other purpose disclosed by us when you provide the information;<br/>
                        2.5. with your consent in any other cases;<br/>
                        2.6. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety
                        of the Company, our customers, or others</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>9. Security of Data</strong></h4>
    
                    <p>The security of your data is important to us but remember that no method of transmission over the
                        Internet or method of electronic storage is 100% secure. While we strive to use commercially
                        acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
    
                    <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your
                        Personal Data.</p>
    
                    <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from
                        our systems, please email us at orderonline@liebigsagrochem.com.<br/>
                        In certain circumstances, you have the following data protection rights:</p>
    
                    <p style={{marginLeft:'40px'}}>1. the right to access, update or to delete the information we have on
                        you;<br/>
                        2. the right of rectification. You have the right to have your information rectified if that
                        information is inaccurate or incomplete;<br/>
                        3. the right of restriction. You have the right to request that we restrict the processing of your
                        personal information;<br/>
                        4. the right to data portability. You have the right to be provided with a copy of your Personal
                        Data in a structured, machine-readable and commonly used format;<br/>
                        5. the right to withdraw consent. You also have the right to withdraw your consent at any time where
                        we rely on your consent to process your personal information;</p>
    
                    <p>Please note that we may ask you to verify your identity before responding to such requests. Please
                        note, we may not be able to provide Service without some necessary data.</p>
    
                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your
                        Personal Data.</p>
    
                    <p style={{marginLeft:'40px'}}>1. users can visit our site anonymously;<br/>
                        2. our Privacy Policy link includes the word “Privacy”, and can easily be found on the home page of
                        our website;<br/>
                        3. users will be notified of any privacy policy changes on our Privacy Policy Page;<br/>
                        4. users are able to change their personal information by emailing us at<strong>
                        orderonline@liebigsagrochem.com.</strong></p>
    
                    <p>Our Policy on “Do Not Track” Signals:</p>
    
                    <p>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track
                        browser mechanism is in place. Do Not Track is a preference you can set in your web browser to
                        inform websites that you do not want to be tracked.</p>
    
                    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
                        browser.</p>
    
                    <p style={{marginLeft:'40px'}}><strong>0.1. What personal information we have about you. If you make this
                            request, we will return to you:</strong><br/>
                        0.0.1. The categories of personal information we have collected about you.<br/>
                        0.0.2. The categories of sources from which we collect your personal information.<br/>
                        0.0.3. The business or commercial purpose for collecting or selling your personal information.<br/>
                        0.0.4. The categories of third parties with whom we share personal information.<br/>
                        0.0.5. The specific pieces of personal information we have collected about you.<br/>
                        0.0.6. A list of categories of personal information that we have sold, along with the category of
                        any other company we sold it to. If we have not sold your personal information, we will inform you
                        of that fact.<br/>
                        0.0.7. A list of categories of personal information that we have disclosed for a business purpose,
                        along with the category of any other company we shared it with.</p>
    
                    <p style={{marginLeft:'40px'}}>Please note, you are entitled to ask us to provide you with this information
                        up to two times in a rolling twelve-month period. When you make this request, the information
                        provided may be limited to the personal information we collected about you in the previous 12
                        months.</p>
    
                    <p style={{marginLeft:'40px'}}><strong>0.2. To delete your personal information. If you make this request,
                            we will delete the personal information we hold about you as of the date of your request from
                            our records and direct any service providers to do the same. In some cases, deletion may be
                            accomplished through de-identification of the information. If you choose to delete your personal
                            information, you may not be able to use certain functions that require your personal information
                            to operate.</strong></p>
    
                    <p style={{marginLeft:'40px'}}><strong>0.3. To stop selling your personal information. We don’t sell or
                            rent your personal information to any third parties for any purpose. We do not sell your
                            personal information for monetary consideration. However, under some circumstances, a transfer
                            of personal information to a third party, or within our family of companies, without monetary
                            consideration may be considered a “sale”. You are the only owner of your Personal Data and can
                            request disclosure or deletion at any time.</strong></p>
    
                    <p style={{marginLeft:'40px'}}>If you submit a request to stop selling your personal information, we will
                        stop making such transfers.</p>
    
                    <p style={{marginLeft:'40px'}}>Please note, if you ask us to delete or stop selling your data, it may
                        impact your experience with us, and you may not be able to participate in certain programs or
                        membership services which require the usage of your personal information to function. But in no
                        circumstances, we will discriminate against you for exercising your rights.</p>
    
                    <p>&nbsp;</p>
    
                    <h4><strong>10. Service Providers</strong></h4>
    
                    <p>We may employ third party companies and individuals to facilitate our Service (<strong>“Service
                            Providers”</strong>), provide Service on our behalf, perform Service-related services or assist
                        us in analysing how our Service is used.</p>
    
                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and
                        are obligated not to disclose or use it for any other purpose.</p>
    
                    <h4><strong>11. Analytics</strong></h4>
    
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
    
                    <h4><strong>12. CI/CD tools</strong></h4>
    
                    <p>We may use third-party Service Providers to automate the development process of our Service.</p>
    
                    <h4><strong>13. Advertising</strong></h4>
    
                    <p>We may use third-party Service Providers to show advertisements to you to help support and maintain
                        our Service.</p>
    
                    <h4><strong>14. Behavioural Remarketing</strong></h4>
    
                    <p>We may use remarketing services to advertise on third party websites to you after you visited our
                        Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your
                        past visits to our Service.</p>
    
                    <h4><strong>15. Payments</strong></h4>
    
                    <p>We may provide paid products and/or services within Service. In that case, we use third-party
                        services for payment processing (e.g. payment processors).</p>
    
                    <p>We will not store or collect your payment card details. That information is provided directly to our
                        third-party payment processors whose use of your personal information is governed by their Privacy
                        Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                        Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                        Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                    </p>
    
                    <h4><strong>16. Links to Other Sites</strong></h4>
    
                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third party
                        link, you will be directed to that third party’s site. We strongly advise you to review the Privacy
                        Policy of every site you visit.</p>
    
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices
                        of any third party sites or services.</p>
    
                    <h4><strong>17. Changes to This Privacy Policy</strong></h4>
    
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
                        new Privacy Policy on this page.</p>
    
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
                        effective and update “effective date” at the top of this Privacy Policy.</p>
    
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.</p>
    
                    <h4><strong>18. Contact Us</strong></h4>
    
                    <p>If you have any questions about this Privacy Policy, please contact us by email:
                        <strong>orderonline@liebigsagrochem.com</strong></p>
    
                    <h4><strong>19. Data Deletion Request</strong></h4>
    
                    <p>If you want to get your data deleted from any of our application, you can request to email:
                        <strong>orderonline@liebigsagrochem.com</strong> or Phone: +91 8100503421</p>
    
                    <p>&nbsp;</p>
    
    
    
                </div>
            </div>
    
    
    
        </div>
    
    </section>
    </>
  )
}

export default PrivacyPolicy