import React, {useState} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import Redirect
import Dashboard from './Dashboard';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';
import { NavLink } from 'react-router-dom';

import LoginService from '../services/LoginService';

function Login() {

    const { param, param2 } = useParams();

    const initialFormData = {
        email: '',
        password: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [redirectToMyProfile, setRedirectToMyProfile] = useState(false); // New state for redirection
    const navigate = useNavigate(); // Add the navigate variable

    const urlparam = param ? param : '';
    const urlparam2 = param2 ? param2 : '';

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFormData(formData);
        setErrors(validationErrors);
        
        if (Object.keys(validationErrors).length === 0) {
            try {
                //console.log('formData',formData);
                const LoginServiceInstance = new LoginService();
                const response = await LoginServiceInstance.login(formData);

                // Handle the response from the API, e.g., show success message, redirect to login page, etc.
                console.log('Login successful:', response);

                // Reset the form after successful submission
                //setFormData(initialFormData);

                // Check the response from the API
                if (response.status && response.status==true) {
                    // Signup successful
                    // setSuccessMessage(response.message);
                    // setErrorMessage(''); // Clear any existing error message
                    // // Reset the form after successful submission
                    // setFormData(initialFormData);
                    
                    localStorage.setItem('userid', response.data.userid);
                    localStorage.setItem('fullname', response.data.fullname);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('phonenumber', response.data.phonenumber);
                    localStorage.setItem('session_key', response.data.session_key);
                    localStorage.setItem('userlogo', response.data.userlogo);
                    localStorage.setItem('isLoggedIn', 1);

                    // Set the state to trigger redirection
                    //navigate('/profile');
                    //alert(urlparam);
                    if(urlparam=='bulkorder'){
                        window.location.href = '/bulk-order/11';
                        //navigate('/bulk-order/11');
                    }else if(urlparam=='product' && urlparam2!=''){
                        window.location.href = '/product-info/'+urlparam2;
                        //navigate('/bulk-order/11');
                    }else{
                        window.location.href = '/';
                    }
                    

                } else {
                    // Signup failed
                    setErrorMessage(response.message);
                    setSuccessMessage(''); // Clear any existing success message
                }

            } catch (error) {

                setErrorMessage('Login failed:', error);
                setSuccessMessage(''); // Clear any existing success message

                // Handle the API error, e.g., show error message, log the error, etc.
                console.error('Login failed:', error);

            }
        }
    };

    // Function to validate form data
    const validateFormData = (data) => {
        const errors = {};

        if (!data.email) {
            errors.email = 'Email / Phone Number is required';
        } 
        // else if (!isValidEmail(data.email)) {
        //     errors.email = 'Invalid email format';
        // }

        if (!data.password) {
            errors.password = 'Password is required';
        }

        
        return errors;
    };


    // Helper function to check for valid email format
    const isValidEmail = (email) => {
        // You can use a regular expression or any other method to validate email format
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

    // if (redirectToMyProfile) {
    //     // Redirect to MyProfile page
    //     return <Redirect to="/profile" />;
    // }

    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>Login</h1>
                    <nav class="breadcrumb ps-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">Login</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row gy-3">
                            {/* <div className="col-lg-4">
                                <div className="list-group" id="list-tab" role="tablist">
                                    <a className="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home"><BiUser /> My Account</a>
                                    
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                {successMessage && <p className="success">{successMessage}</p>}
                                {errorMessage && <p className="error">{errorMessage}</p>}

                                <div className="tab-content" id="nav-tabContent">
                                    
                                    <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                        <form onSubmit={handleSubmit}>
                                            <div className='profile_info'>
                                                <div className="row">
                                                    <label>Email / Phone Number</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} />
                                                            {errors.email && <span className="error">{errors.email}</span>}
                                                        </div>
                                                    </div>
                                                    <label>Password</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} />
                                                            {errors.password && <span className="error">{errors.password}</span>}
                                                        </div>
                                                    </div>
                                                    <div className='text-right' style={{ textAlign: 'right' }} >
                                                    <NavLink to={`/forgot-password/${urlparam}/${urlparam2}`}>Forgotten password?</NavLink>
                                                    </div>
                                                    <div>
                                                        <button className='btn_2' type="submit">Login</button>
                                                    </div>
                                                    <br/><br/><br/><hr/><br/><br/>
                                                    <div className='text-center' >
                                                        <NavLink to={`/signup/${urlparam}/${urlparam2}`}className='btn_2'>Create New Account</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </form> 
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='text-center'>
                                    <img src={icon1} alt="shoppingCart" className='img-fluid' width="130px"/>
                                    <h5>Continue Shopping</h5>
                                    <NavLink class="btn btn-success p-2" to="/" role="button">Shop Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login