import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';
import defaultuserlogo from '../assets/img/users/default.png';
import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import PaymentService from '../services/PaymentService';

function PaymentError() {
    
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false); // Add a state to track if the isLoggedIn check is complete
    const [apiData, setApiData] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('Payment Unsuccessful');
    const [paymentStatusClass, setPaymentStatusClass] = useState('error');

    
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
        }
    }, [navigate]);

    useEffect(() => {
        setPaymentStatus('API Error');
        setPaymentStatusClass('error');
    }, []);
   
    // Render a loading indicator while the isLoggedIn check is in progress
    if (!isLoggedInChecked) {
        return <div>Loading...</div>;
    }


    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>Payment Status</h1>
                    <nav class="breadcrumb ps-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">Payment Status</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className={paymentStatusClass}>{paymentStatus}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                        <h5>Continue Shopping</h5>
                        <NavLink class="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                    </div>
            
                </div>
            </section>
        </>
    )
}

export default PaymentError